import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Stores.module.sass";
import { use100vh } from "react-div-100vh";

import Loader from "../../../components/Loader";

import { useSelector, useDispatch } from "react-redux";
import { login, reset } from "../../../features/auth/authSlice";
import Image from "../../../components/Image";

import ppg_logo from "../../../assets/images/logo_ppg.svg";
import ppg_logo_in_dark from "../../../assets/images/ppg_logo_in_dark.svg"

import CustomToast from "../../../components/CustomToast";
import store_dark_image from "../../../assets/images/store_logo_dark.svg";
import store_light_image from "../../../assets/images/store_logo_light.svg";

const SignIn = ({ onConfirm, userData, setUserData, selectedTab, setSelectedTab,inputValue, setInputValue}) => {
  const heightWindow = use100vh();

  const { stores, isSuccess, message, isLoading } = useSelector(
    (state) => state.auth
  );

  const dispatch = useDispatch();
  const showToast = CustomToast();
  useEffect(() => {
    if (isSuccess) {
      showToast(`OTP send to your email address`, "success");

      onConfirm();
    } else if (message) {
      showToast(message.data.err, "error");
    }
    dispatch(reset());
  }, [isSuccess, message]);

  const handleSignIn = async (store_id) => {
    try {
      setUserData((prevUser) => ({
        ...prevUser,
        store_id: store_id,
      }));      
      if (selectedTab === "email") {
        dispatch(login({ email: userData.email, store_id: store_id }));
      } else if (selectedTab === "phone") {
        onConfirm();
      }
    } catch (error) {
      console.err("err", error);
    }
  };

  return (
    <div style={{ minHeight: heightWindow }}>
      <div className={styles.modal}>
        <div className={cn(styles.outer)}>
          {isLoading ? (
            <div className={styles.loader_container}>
              <Loader className={styles.loader} />
            </div>
          ) : (
            <div>
              <div className={styles.header}>
                <Image
                  className={styles.pic}
                  src={ppg_logo}
                  srcDark={ppg_logo_in_dark}
                  alt="Core"
                />{" "}
                <h3>PickPackGo</h3>
              </div>
              <h2 className={styles.subheader}>Choose a store</h2>
              <p>
                Your email is associated with multiple stores. Please select the
                store you would like to continue with.
              </p>
              {stores.map((store) => {
                return (
                  <div
                    className={styles.store_container}
                    onClick={() => handleSignIn(store.store.id)}
                  >
                    <div className={styles.store_wrapper}>
                      <div className={styles.store_img_wrapper}>
                        {store.store?.store_logo ? (
                          <Image
                            src={store.store?.store_logo }
                            srcDark={store.store?.store_logo }
                            alt="Core"
                            className={styles.store_logo_img}
                          />
                        ) : (
                          <Image
                            src={store_light_image}
                            srcDark={store_dark_image}
                            alt="Core"
                            className={styles.store_logo}
                          />
                        )}
                      </div>
                      <p>{store.store.store_name}</p>
                    </div>
                    <hr />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
