import React from "react";
import useDarkMode from "use-dark-mode";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { getInputStyle, getContainerStyle, getButtonStyle, getDropdownStyle } from "./CustomPhoneStyles";

const CustomPhoneInput = ({
  value,
  onChange,
  error,
  placeholder = "Your phone number",
  width,
}) => {
  const darkMode = useDarkMode(false);
  const handlePhoneChange = (phone) => {
    const formattedPhone = phone.startsWith('+') ? phone : `+${phone}`;
    onChange(formattedPhone);
  };
  return (
    <div className="relative">
      <PhoneInput
        value={value}
        onChange={handlePhoneChange}
        inputProps={{
          required: true,
          placeholder: placeholder,
        }}
        country={'in'}
        inputStyle={getInputStyle(darkMode.value, width)}
        containerStyle={getContainerStyle(darkMode.value)}
        buttonStyle={getButtonStyle(darkMode.value)}
        dropdownStyle={getDropdownStyle(darkMode.value)}
      />
      {error && <div className="text-red-500 text-sm mt-1">{error}</div>}
    </div>
  );
};

export default CustomPhoneInput;
