import { useLocation, useNavigate, Navigate, Outlet } from "react-router-dom";
import Modal from "../../components/Modal";
import { Divider } from "antd";
import cn from "classnames";

import styles from "./ProtectedRoute.module.sass";
import { useEffect, useState } from "react";
import { get_all_cameras } from "../../API/Camera";
import { getStoredCameras } from "../../utils/cameraUtils";

const ProtectedRoute = () => {
  const navigate = useNavigate();
  const jwtToken = localStorage.getItem("token");
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const updateCamerasWithLocalStorage = async () => {
    try {
      // Fetch cameras from the API
      const res = await get_all_cameras();
      const apiCameras = res.data;

      // Get cameras from local storage
      const storedCameras = getStoredCameras();
      const storedCamerasMap = new Map(
        storedCameras.map((camera) => [camera.id, camera])
      );

      // Filter and update API cameras with brand names from local storage
      const linkedCameras = apiCameras
        .filter((camera) => storedCamerasMap.has(camera.id))
        .map((camera) => {
          const storedCamera = storedCamerasMap.get(camera.id);
          return {
            ...camera,
            brand: storedCamera.brand,
          };
        });

      return linkedCameras;
    } catch (error) {
      console.error("Error updating cameras with local storage data:", error);
      throw error;
    }
  };
  const fetchAllCamaras = async () => {
    const updatedCameras = await updateCamerasWithLocalStorage();
    if (updatedCameras.length === 0) {
      setOpen(true);
    }

    // setCamras(updatedCameras);
  };

  useEffect(() => {
    fetchAllCamaras();
  }, []);
  useEffect(() => {
    const localCamera = localStorage.getItem("Camera");
    const camera = localCamera ? JSON.parse(localCamera) : [];
    if (camera.length === 0) {
      setOpen(true);
    }
  }, []);
  return (
    <>
      {!jwtToken && (
        <Navigate to="/sign-in" state={{ from: location }} replace />
      )}
      {jwtToken && <Outlet />}    
    </>
  );
};
export default ProtectedRoute;
