import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./SignIn.module.sass";
import { use100vh } from "react-div-100vh";
import Loader from "../../../components/Loader";
import TextInput from "../../../components/TextInput";
import { useSelector, useDispatch } from "react-redux";
import {
  getStores,
  getStoresWithMobile,
  reset,
} from "../../../features/auth/authSlice";
import CustomToast from "../../../components/CustomToast";
import * as Yup from "yup";
import Icon from "../../../components/Icon";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../../config/firebaseConfig";
import CustomPhoneInput from "../../../components/CustomPhoneInput/CustomPhoneInput";
const SignIn = ({
  onConfirm,
  setUserData,
  selectedTab,
  setSelectedTab,
  inputValue,
  setInputValue,
  setConfirmationResult,
}) => {
  const heightWindow = use100vh();
  const [email, setEmail] = useState("");

  const [validationErrors, setValidationErrors] = useState({});

  const { token, isSuccess, message, isLoading, stores } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const showToast = CustomToast();

  const validationSchema = Yup.object().shape({
    email:
      selectedTab === "email"
        ? Yup.string().email("Invalid email").required("Email is required")
        : Yup.string(),
    phone:
      selectedTab === "phone"
        ? Yup.string().required("Phone number is required")
        : Yup.string(),
  });

  useEffect(() => {}, [token, message]);

  useEffect(() => {
    setValidationErrors({});
  }, [inputValue]);
 
  useEffect(() => {
    if (isSuccess) {
      if (stores && stores.length > 0) {
        showToast("Select store", "success");

        onConfirm();
      } else {
        showToast("User not found", "error");
      }
    } else if (message) {
      showToast(message.data.err, "error");
    }
    dispatch(reset());
  }, [isSuccess, message, stores]);
  const handleSignIn = async () => {
    if (selectedTab === "email") {
      try {
        await validationSchema.validate(
          { email: inputValue },
          { abortEarly: false }
        );
        dispatch(getStores({ email: inputValue }));
        setUserData({ email: inputValue });
      } catch (error) {
        handleValidationError(error);
      }
    } else {
      await sendVerificationCode();
    }
  };
  const setupRecaptchaVerifier = () => {
    const recaptchaContainer = document.getElementById("recaptcha-container");
    if (recaptchaContainer && !window.recaptchaVerifier) {
      try {
        window.recaptchaVerifier = new RecaptchaVerifier(
          auth,
          "recaptcha-container",
          {
            size: "invisible",
            callback: (response) => {
              window.recaptchaVerifier.recaptcha.reset();
            },
            "expired-callback": () => {
              window.recaptchaVerifier.recaptcha.reset();
            },
            "error-callback": () => {
              window.recaptchaVerifier.recaptcha.reset();
            },
          }
        );
      } catch (error) {
        console.error("Error setting up reCAPTCHA:", error);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
      }
    };
  }, []);

  const sendVerificationCode = async () => {
    try {
      await validationSchema.validate(
        { phone: inputValue },
        { abortEarly: false }
      );
      setupRecaptchaVerifier();
      const phoneNumber = inputValue;
      const confirmation = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        window.recaptchaVerifier
      );
      setConfirmationResult(confirmation);
      showToast("Verification code sent", "success");
      dispatch(getStoresWithMobile({ mobile_no: inputValue }));
      setUserData({ mobile_no: inputValue });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        handleValidationError(error);
      } else {
        console.error("Phone auth error:", error);
        showToast("Failed to send verification code", "error");
      }
    }
  };
  const handleValidationError = (error) => {
    const errors = {};
    error.inner.forEach((fieldError) => {
      errors[fieldError.path] = fieldError.message;
    });
    setValidationErrors(errors);
  };
  const handlePhoneChange = (value) => {
    setInputValue(value);
  };
  useEffect(() => {
    setInputValue('');
    setValidationErrors({});
  }, [selectedTab]);
  return (
    <div className={styles.login} style={{ minHeight: heightWindow }}>
      <div className={styles.wrapper}>
        <div className={styles.body}>
          <div className={styles.head}>
            <div className={styles.btns}>
              <button
                className={cn(styles.buttonhead,styles.button, {
                  [styles.active]: selectedTab === "email"
                })}
                onClick={() => setSelectedTab("email")}
              >
                <Icon className={styles.pic} name="mail" size="24" />
                Email
              </button>
              <button
                className={cn(styles.buttonhead,styles.button, {
                  [styles.active]: selectedTab === "phone"
                })}
                onClick={() => setSelectedTab("phone")}
              >
                <Icon className={styles.pic} name="phone" size="24" />
                Phone
              </button>
            </div>
          </div>

          {selectedTab === "email" ? (
            <TextInput
              className={cn(styles.field, {
                [styles.validationError]: validationErrors.email,
              })}
              name="email"
              type="email"
              placeholder="Your email"
              required
              icon="mail"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          ) : (
            <>
              <CustomPhoneInput
                value={inputValue}
                onChange={handlePhoneChange}
                error={validationErrors.phone}
                width="100%"
              />
             
            </>
          )}
          <div className="mb-[20px] mt-[10px]">
            <div id="recaptcha-container"></div>
          </div>
          {validationErrors.email && (
            <div className={styles.error}>{validationErrors.email}</div>
          )}
          {validationErrors.phone && (
            <div className={styles.error}>{validationErrors.phone}</div>
          )}

          <button
            className={cn("button", styles.button)}
            onClick={handleSignIn}
          >
            {isLoading ? <Loader /> : "Sign in"}
          </button>

          <div className={styles.note}>
            By Signing in you accept our{" "}
            <span className={cn(styles.note, "text-black font-bold")}>
              Terms of Service & Privacy Policy
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
