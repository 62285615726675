import React, { useState, useRef, useEffect } from "react";
import { countries } from "./countries";
import TextInput from "../TextInput";
import useDarkMode from "use-dark-mode";

const IntlPhonePicker = ({ onChange, defaultPhone }) => {
  const indiaCountry = countries.find((country) => country.name === "India");
  const [selectedCountry, setSelectedCountry] = useState(indiaCountry);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef(null);
  const darkMode = useDarkMode(false);

  useEffect(() => {
    if (defaultPhone) {
      const dialCodeLength = selectedCountry.dialCode.length;
      const phoneWithoutDialCode = defaultPhone.slice(dialCodeLength);
      setPhoneNumber(phoneWithoutDialCode);
      const country = countries.find((country) =>
        defaultPhone.startsWith(country.dialCode)
      );
      if (country) {
        setSelectedCountry(country);
      }
    }
  }, [defaultPhone]);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (country) => {
    setSelectedCountry(country);
    setIsOpen(false);
    if (onChange) {
      onChange(`${country.dialCode}${phoneNumber}`);
    }
  };

  const handlePhoneChange = (event) => {
    const newPhoneNumber = event.target.value.replace(/\D/g, "");
    setPhoneNumber(newPhoneNumber);
    if (onChange) {
      onChange(`${selectedCountry.dialCode}${newPhoneNumber}`);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredCountries = countries.filter((country) =>
    country.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex items-center">
      <div ref={dropdownRef} className="relative">
        <div
          onClick={toggleDropdown}
          className="cursor-pointer p-2 flex items-center rounded-lg shadow-inner border border-[#8A9199]"
        >
          <img
            src={selectedCountry.flag}
            alt={`${selectedCountry.name} flag`}
            className="w-8 mr-2"
          />
        </div>
        {isOpen && (
          <div
            className={`absolute z-50 ${
              darkMode.value
                ? "bg-[#111315] text-gray-300"
                : "bg-white text-gray-600"
            } w-72 max-h-58 overflow-y-auto p-2 mt-5 rounded-xl shadow-inner border-2 border-[#6F767E]`}
          >
            <TextInput
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="p-2 border-b border-gray-300 w-full"
            />
            {filteredCountries.map((country) => (
              <div
                key={country.code}
                onClick={() => handleOptionClick(country)}
                className={`p-2 cursor-pointer flex items-center ${
                  selectedCountry.code === country.code
                    ? "text-blue-600"
                    : darkMode.value
                    ? "text-gray-300 hover:text-white"
                    : "text-gray-600 hover:text-black"
                }`}
              >
                <img
                  src={country.flag}
                  alt={`${country.name} flag`}
                  className="w-5 mr-2"
                />
                {country.name} ({country.dialCode})
              </div>
            ))}
          </div>
        )}
      </div>
      <TextInput
        type="tel"
        placeholder="Enter phone number"
        value={phoneNumber}
        onChange={handlePhoneChange}
        className="ml-2 w-[100%]"
      />
    </div>
  );
};

export default IntlPhonePicker;
