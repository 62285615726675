export const countries = [
  { code: 'AF', name: 'Afghanistan', dialCode: '+93', flag: 'https://flagcdn.com/af.svg' },
  { code: 'AX', name: 'Åland Islands', dialCode: '+358', flag: 'https://flagcdn.com/ax.svg' },
  { code: 'AL', name: 'Albania', dialCode: '+355', flag: 'https://flagcdn.com/al.svg' },
  { code: 'DZ', name: 'Algeria', dialCode: '+213', flag: 'https://flagcdn.com/dz.svg' },
  { code: 'AS', name: 'American Samoa', dialCode: '+1-684', flag: 'https://flagcdn.com/as.svg' },
  { code: 'AD', name: 'Andorra', dialCode: '+376', flag: 'https://flagcdn.com/ad.svg' },
  { code: 'AO', name: 'Angola', dialCode: '+244', flag: 'https://flagcdn.com/ao.svg' },
  { code: 'AI', name: 'Anguilla', dialCode: '+1-264', flag: 'https://flagcdn.com/ai.svg' },
  { code: 'AQ', name: 'Antarctica', dialCode: '+672', flag: 'https://flagcdn.com/aq.svg' },
  { code: 'AG', name: 'Antigua and Barbuda', dialCode: '+1-268', flag: 'https://flagcdn.com/ag.svg' },
  { code: 'AR', name: 'Argentina', dialCode: '+54', flag: 'https://flagcdn.com/ar.svg' },
  { code: 'AM', name: 'Armenia', dialCode: '+374', flag: 'https://flagcdn.com/am.svg' },
  { code: 'AW', name: 'Aruba', dialCode: '+297', flag: 'https://flagcdn.com/aw.svg' },
  { code: 'AU', name: 'Australia', dialCode: '+61', flag: 'https://flagcdn.com/au.svg' },
  { code: 'AT', name: 'Austria', dialCode: '+43', flag: 'https://flagcdn.com/at.svg' },
  { code: 'AZ', name: 'Azerbaijan', dialCode: '+994', flag: 'https://flagcdn.com/az.svg' },
  { code: 'BS', name: 'Bahamas', dialCode: '+1-242', flag: 'https://flagcdn.com/bs.svg' },
  { code: 'BH', name: 'Bahrain', dialCode: '+973', flag: 'https://flagcdn.com/bh.svg' },
  { code: 'BD', name: 'Bangladesh', dialCode: '+880', flag: 'https://flagcdn.com/bd.svg' },
  { code: 'BB', name: 'Barbados', dialCode: '+1-246', flag: 'https://flagcdn.com/bb.svg' },
  { code: 'BY', name: 'Belarus', dialCode: '+375', flag: 'https://flagcdn.com/by.svg' },
  { code: 'BE', name: 'Belgium', dialCode: '+32', flag: 'https://flagcdn.com/be.svg' },
  { code: 'BZ', name: 'Belize', dialCode: '+501', flag: 'https://flagcdn.com/bz.svg' },
  { code: 'BJ', name: 'Benin', dialCode: '+229', flag: 'https://flagcdn.com/bj.svg' },
  { code: 'BM', name: 'Bermuda', dialCode: '+1-441', flag: 'https://flagcdn.com/bm.svg' },
  { code: 'BT', name: 'Bhutan', dialCode: '+975', flag: 'https://flagcdn.com/bt.svg' },
  { code: 'BO', name: 'Bolivia', dialCode: '+591', flag: 'https://flagcdn.com/bo.svg' },
  { code: 'BA', name: 'Bosnia and Herzegovina', dialCode: '+387', flag: 'https://flagcdn.com/ba.svg' },
  { code: 'BW', name: 'Botswana', dialCode: '+1-441', flag: 'https://flagcdn.com/bw.svg' },
  { code: 'BV', name: 'Bouvet Island', dialCode: '+47', flag: 'https://flagcdn.com/bv.svg' },
  { code: 'BR', name: 'Brazil', dialCode: '+55', flag: 'https://flagcdn.com/br.svg' },
  { code: 'IO', name: 'British Indian Ocean Territory', dialCode: '+246', flag: 'https://flagcdn.com/io.svg' },
  { code: 'BN', name: 'Brunei Darussalam', dialCode: '+673', flag: 'https://flagcdn.com/bn.svg' },
  { code: 'BG', name: 'Bulgaria', dialCode: '+359', flag: 'https://flagcdn.com/bg.svg' },
  { code: 'BF', name: 'Burkina Faso', dialCode: '+226', flag: 'https://flagcdn.com/bf.svg' },
  { code: 'BI', name: 'Burundi', dialCode: '+257', flag: 'https://flagcdn.com/bi.svg' },
  { code: 'KH', name: 'Cambodia', dialCode: '+855', flag: 'https://flagcdn.com/kh.svg' },
  { code: 'CM', name: 'Cameroon', dialCode: '+237', flag: 'https://flagcdn.com/cm.svg' },
  { code: 'CA', name: 'Canada', dialCode: '+1', flag: 'https://flagcdn.com/ca.svg' },
  { code: 'CV', name: 'Cape Verde', dialCode: '+238', flag: 'https://flagcdn.com/cv.svg' },
  { code: 'KY', name: 'Cayman Islands', dialCode: '+1 345', flag: 'https://flagcdn.com/ky.svg' },
  { code: 'CF', name: 'Central African Republic', dialCode: '+236', flag: 'https://flagcdn.com/cf.svg' },
  { code: 'TD', name: 'Chad', dialCode: '+235', flag: 'https://flagcdn.com/td.svg' },
  { code: 'CL', name: 'Chile', dialCode: '+56', flag: 'https://flagcdn.com/cl.svg' },
  { code: 'CN', name: 'China', dialCode: '+86', flag: 'https://flagcdn.com/cn.svg' },
  { code: 'CX', name: 'Christmas Island', dialCode: '+61', flag: 'https://flagcdn.com/cx.svg' },
  { code: 'CC', name: 'Cocos (Keeling) Islands', dialCode: '+61', flag: 'https://flagcdn.com/cc.svg' },
  { code: 'CO', name: 'Colombia', dialCode: '+57', flag: 'https://flagcdn.com/co.svg' },
  { code: 'KM', name: 'Comoros', dialCode: '+269', flag: 'https://flagcdn.com/km.svg' },
  { code: 'CG', name: 'Congo', dialCode: '+242', flag: 'https://flagcdn.com/cg.svg' },
  { code: 'CD', name: 'Congo, The Democratic Republic of the', dialCode: '+243', flag: 'https://flagcdn.com/cd.svg' },
  { code: 'CK', name: 'Cook Islands', dialCode: '+682', flag: 'https://flagcdn.com/ck.svg' },
  { code: 'CR', name: 'Costa Rica', dialCode: '+506', flag: 'https://flagcdn.com/cr.svg' },
  { code: 'CI', name: 'Cote D\'Ivoire', dialCode: '+225', flag: 'https://flagcdn.com/ci.svg' },
  { code: 'HR', name: 'Croatia', dialCode: '+385', flag: 'https://flagcdn.com/hr.svg' },
  { code: 'CU', name: 'Cuba', dialCode: '+53', flag: 'https://flagcdn.com/cu.svg' },
  { code: 'CY', name: 'Cyprus', dialCode: '+357', flag: 'https://flagcdn.com/cy.svg' },
  { code: 'CZ', name: 'Czech Republic', dialCode: '+420', flag: 'https://flagcdn.com/cz.svg' },
  { code: 'DK', name: 'Denmark', dialCode: '+45', flag: 'https://flagcdn.com/dk.svg' },
  { code: 'DJ', name: 'Djibouti', dialCode: '+253', flag: 'https://flagcdn.com/dj.svg' },
  { code: 'DM', name: 'Dominica', dialCode: '+1-767', flag: 'https://flagcdn.com/dm.svg' },
  { code: 'DO', name: 'Dominican Republic', dialCode: '+1-809', flag: 'https://flagcdn.com/do.svg' },
  { code: 'EC', name: 'Ecuador', dialCode: '+593', flag: 'https://flagcdn.com/ec.svg' },
  { code: 'EG', name: 'Egypt', dialCode: '+20', flag: 'https://flagcdn.com/eg.svg' },
  { code: 'SV', name: 'El Salvador', dialCode: '+503', flag: 'https://flagcdn.com/sv.svg' },
  { code: 'GQ', name: 'Equatorial Guinea', dialCode: '+240', flag: 'https://flagcdn.com/gq.svg' },
  { code: 'ER', name: 'Eritrea', dialCode: '+291', flag: 'https://flagcdn.com/er.svg' },
  { code: 'EE', name: 'Estonia', dialCode: '+372', flag: 'https://flagcdn.com/ee.svg' },
  { code: 'ET', name: 'Ethiopia', dialCode: '+251', flag: 'https://flagcdn.com/et.svg' },
  { code: 'FK', name: 'Falkland Islands (Malvinas)', dialCode: '+500', flag: 'https://flagcdn.com/fk.svg' },
  { code: 'FO', name: 'Faroe Islands', dialCode: '+298', flag: 'https://flagcdn.com/fo.svg' },
  { code: 'FJ', name: 'Fiji', dialCode: '+679', flag: 'https://flagcdn.com/fj.svg' },
  { code: 'FI', name: 'Finland', dialCode: '+358', flag: 'https://flagcdn.com/fi.svg' },
  { code: 'FR', name: 'France', dialCode: '+33', flag: 'https://flagcdn.com/fr.svg' },
  { code: 'GF', name: 'French Guiana', dialCode: '+594', flag: 'https://flagcdn.com/gf.svg' },
  { code: 'PF', name: 'French Polynesia', dialCode: '+689', flag: 'https://flagcdn.com/pf.svg' },
  { code: 'TF', name: 'French Southern Territories', dialCode: '+262', flag: 'https://flagcdn.com/tf.svg' },
  { code: 'GA', name: 'Gabon', dialCode: '+241', flag: 'https://flagcdn.com/ga.svg' },
  { code: 'GM', name: 'Gambia', dialCode: '+220', flag: 'https://flagcdn.com/gm.svg' },
  { code: 'GE', name: 'Georgia', dialCode: '+995', flag: 'https://flagcdn.com/ge.svg' },
  { code: 'DE', name: 'Germany', dialCode: '+49', flag: 'https://flagcdn.com/de.svg' },
  { code: 'GH', name: 'Ghana', dialCode: '+233', flag: 'https://flagcdn.com/gh.svg' },
  { code: 'GI', name: 'Gibraltar', dialCode: '+350', flag: 'https://flagcdn.com/gi.svg' },
  { code: 'GR', name: 'Greece', dialCode: '+30', flag: 'https://flagcdn.com/gr.svg' },
  { code: 'GL', name: 'Greenland', dialCode: '+299', flag: 'https://flagcdn.com/gl.svg' },
  { code: 'GD', name: 'Grenada', dialCode: '+1-473', flag: 'https://flagcdn.com/gd.svg' },
  { code: 'GP', name: 'Guadeloupe', dialCode: '+590', flag: 'https://flagcdn.com/gp.svg' },
  { code: 'GU', name: 'Guam', dialCode: '+1-671', flag: 'https://flagcdn.com/gu.svg' },
  { code: 'GT', name: 'Guatemala', dialCode: '+502', flag: 'https://flagcdn.com/gt.svg' },
  { code: 'GG', name: 'Guernsey', dialCode: '+44-1481', flag: 'https://flagcdn.com/gg.svg' },
  { code: 'GN', name: 'Guinea', dialCode: '+224', flag: 'https://flagcdn.com/gn.svg' },
  { code: 'GW', name: 'Guinea-Bissau', dialCode: '+245', flag: 'https://flagcdn.com/gw.svg' },
  { code: 'GY', name: 'Guyana', dialCode: '+592', flag: 'https://flagcdn.com/gy.svg' },
  { code: 'HT', name: 'Haiti', dialCode: '+509', flag: 'https://flagcdn.com/ht.svg' },
  { code: 'HM', name: 'Heard Island and Mcdonald Islands', dialCode: '+61', flag: 'https://flagcdn.com/hm.svg' },
  { code: 'VA', name: 'Holy See (Vatican City State)', dialCode: '+379', flag: 'https://flagcdn.com/va.svg' },
  { code: 'HN', name: 'Honduras', dialCode: '+504', flag: 'https://flagcdn.com/hn.svg' },
  { code: 'HK', name: 'Hong Kong', dialCode: '+852', flag: 'https://flagcdn.com/hk.svg' },
  { code: 'HU', name: 'Hungary', dialCode: '+36', flag: 'https://flagcdn.com/hu.svg' },
  { code: 'IS', name: 'Iceland', dialCode: '+354', flag: 'https://flagcdn.com/is.svg' },
  { code: 'IN', name: 'India', dialCode: '+91', flag: 'https://flagcdn.com/in.svg' },
  { code: 'ID', name: 'Indonesia', dialCode: '+62', flag: 'https://flagcdn.com/id.svg' },
  { code: 'IR', name: 'Iran, Islamic Republic Of', dialCode: '+98', flag: 'https://flagcdn.com/ir.svg' },
  { code: 'IQ', name: 'Iraq', dialCode: '+964', flag: 'https://flagcdn.com/iq.svg' },
  { code: 'IE', name: 'Ireland', dialCode: '+353', flag: 'https://flagcdn.com/ie.svg' },
  { code: 'IM', name: 'Isle of Man', dialCode: '+44', flag: 'https://flagcdn.com/im.svg' },
  { code: 'IL', name: 'Israel', dialCode: '+972', flag: 'https://flagcdn.com/il.svg' },
  { code: 'IT', name: 'Italy', dialCode: '+39', flag: 'https://flagcdn.com/it.svg' },
  { code: 'JM', name: 'Jamaica', dialCode: '+1-876', flag: 'https://flagcdn.com/jm.svg' },
  { code: 'JP', name: 'Japan', dialCode: '+81', flag: 'https://flagcdn.com/jp.svg' },
//  { code: 'JE', name: 'Jersey', dialCode: '+44', flag: 'https://flagcdn.com/je.svg' },
  { code: 'JO', name: 'Jordan', dialCode: '+962', flag: 'https://flagcdn.com/jo.svg' },
  { code: 'KZ', name: 'Kazakhstan', dialCode: '+7', flag: 'https://flagcdn.com/kz.svg' },
  { code: 'KE', name: 'Kenya', dialCode: '+254', flag: 'https://flagcdn.com/ke.svg' },
  { code: 'KI', name: 'Kiribati', dialCode: '+686', flag: 'https://flagcdn.com/ki.svg' },
  { code: 'KP', name: 'Korea, Democratic People\'S Republic of', dialCode: '+850', flag: 'https://flagcdn.com/kp.svg' },
  { code: 'KR', name: 'Korea, Republic of', dialCode: '+82', flag: 'https://flagcdn.com/kr.svg' },
  { code: 'KW', name: 'Kuwait', dialCode: '+965', flag: 'https://flagcdn.com/kw.svg' },
  { code: 'KG', name: 'Kyrgyzstan', dialCode: '+996', flag: 'https://flagcdn.com/kg.svg' },
  { code: 'LA', name: 'Lao People\'S Democratic Republic', dialCode: '+856', flag: 'https://flagcdn.com/la.svg' },
  { code: 'LV', name: 'Latvia', dialCode: '+371', flag: 'https://flagcdn.com/lv.svg' },
  { code: 'LB', name: 'Lebanon', dialCode: '+961', flag: 'https://flagcdn.com/lb.svg' },
  { code: 'LS', name: 'Lesotho', dialCode: '+266', flag: 'https://flagcdn.com/ls.svg' },
  { code: 'LR', name: 'Liberia', dialCode: '+231', flag: 'https://flagcdn.com/lr.svg' },
  { code: 'LY', name: 'Libyan Arab Jamahiriya', dialCode: '+218', flag: 'https://flagcdn.com/ly.svg' },
  { code: 'LI', name: 'Liechtenstein', dialCode: '+423', flag: 'https://flagcdn.com/li.svg' },
  { code: 'LT', name: 'Lithuania', dialCode: '+370', flag: 'https://flagcdn.com/lt.svg' },
  { code: 'LU', name: 'Luxembourg', dialCode: '+352', flag: 'https://flagcdn.com/lu.svg' },
  { code: 'MO', name: 'Macao', dialCode: '+853', flag: 'https://flagcdn.com/mo.svg' },
  { code: 'MK', name: 'Macedonia, The Former Yugoslav Republic of', dialCode: '+389', flag: 'https://flagcdn.com/mk.svg' },
  { code: 'MG', name: 'Madagascar', dialCode: '+261', flag: 'https://flagcdn.com/mg.svg' },
  { code: 'MW', name: 'Malawi', dialCode: '+265', flag: 'https://flagcdn.com/mw.svg' },
  { code: 'MY', name: 'Malaysia', dialCode: '+60', flag: 'https://flagcdn.com/my.svg' },
  { code: 'MV', name: 'Maldives', dialCode: '+960', flag: 'https://flagcdn.com/mv.svg' },
  { code: 'ML', name: 'Mali', dialCode: '+223', flag: 'https://flagcdn.com/ml.svg' },
  { code: 'MT', name: 'Malta', dialCode: '+356', flag: 'https://flagcdn.com/mt.svg' },
  { code: 'MH', name: 'Marshall Islands', dialCode: '+692', flag: 'https://flagcdn.com/mh.svg' },
  { code: 'MQ', name: 'Martinique', dialCode: '+596', flag: 'https://flagcdn.com/mq.svg' },
  { code: 'MR', name: 'Mauritania', dialCode: '+222', flag: 'https://flagcdn.com/mr.svg' },
  { code: 'MU', name: 'Mauritius', dialCode: '+230', flag: 'https://flagcdn.com/mu.svg' },
  { code: 'YT', name: 'Mayotte', dialCode: '+262', flag: 'https://flagcdn.com/yt.svg' },
  { code: 'MX', name: 'Mexico', dialCode: '+52', flag: 'https://flagcdn.com/mx.svg' },
  { code: 'FM', name: 'Micronesia, Federated States of', dialCode: '+691', flag: 'https://flagcdn.com/fm.svg' },
  { code: 'MD', name: 'Moldova, Republic of', dialCode: '+373', flag: 'https://flagcdn.com/md.svg' },
  { code: 'MC', name: 'Monaco', dialCode: '+377', flag: 'https://flagcdn.com/mc.svg' },
  { code: 'MN', name: 'Mongolia', dialCode: '+976', flag: 'https://flagcdn.com/mn.svg' },
  { code: 'MS', name: 'Montserrat', dialCode: '+1664', flag: 'https://flagcdn.com/ms.svg' },
  { code: 'MA', name: 'Morocco', dialCode: '+212', flag: 'https://flagcdn.com/ma.svg' },
  { code: 'MZ', name: 'Mozambique', dialCode: '+258', flag: 'https://flagcdn.com/mz.svg' },
  { code: 'MM', name: 'Myanmar', dialCode: '+95', flag: 'https://flagcdn.com/mm.svg' },
  { code: 'NA', name: 'Namibia', dialCode: '+264', flag: 'https://flagcdn.com/na.svg' },
  { code: 'NR', name: 'Nauru', dialCode: '+674', flag: 'https://flagcdn.com/nr.svg' },
  { code: 'NP', name: 'Nepal', dialCode: '+977', flag: 'https://flagcdn.com/np.svg' },
  { code: 'NL', name: 'Netherlands', dialCode: '+31', flag: 'https://flagcdn.com/nl.svg' },
//   { code: 'AN', name: 'Netherlands Antilles', dialCode: '+599', flag: 'https://flagcdn.com/an.svg' },
  { code: 'NC', name: 'New Caledonia', dialCode: '+687', flag: 'https://flagcdn.com/nc.svg' },
  { code: 'NZ', name: 'New Zealand', dialCode: '+64', flag: 'https://flagcdn.com/nz.svg' },
  { code: 'NI', name: 'Nicaragua', dialCode: '+505', flag: 'https://flagcdn.com/ni.svg' },
  { code: 'NE', name: 'Niger', dialCode: '+227', flag: 'https://flagcdn.com/ne.svg' },
  { code: 'NG', name: 'Nigeria', dialCode: '+234', flag: 'https://flagcdn.com/ng.svg' },
  { code: 'NU', name: 'Niue', dialCode: '+683', flag: 'https://flagcdn.com/nu.svg' },
  { code: 'NF', name: 'Norfolk Island', dialCode: '+6723', flag: 'https://flagcdn.com/nf.svg' },
  { code: 'MP', name: 'Northern Mariana Islands', dialCode: '+1670', flag: 'https://flagcdn.com/mp.svg' },
  { code: 'NO', name: 'Norway', dialCode: '+47', flag: 'https://flagcdn.com/no.svg' },
  { code: 'OM', name: 'Oman', dialCode: '+968', flag: 'https://flagcdn.com/om.svg' },
  { code: 'PK', name: 'Pakistan', dialCode: '+92', flag: 'https://flagcdn.com/pk.svg' },
  { code: 'PW', name: 'Palau', dialCode: '+680', flag: 'https://flagcdn.com/pw.svg' },
  { code: 'PS', name: 'Palestinian Territory, Occupied', dialCode: '+970', flag: 'https://flagcdn.com/ps.svg' },
  { code: 'PA', name: 'Panama', dialCode: '+507', flag: 'https://flagcdn.com/pa.svg' },
  { code: 'PG', name: 'Papua New Guinea', dialCode: '+675', flag: 'https://flagcdn.com/pg.svg' },
  { code: 'PY', name: 'Paraguay', dialCode: '+595', flag: 'https://flagcdn.com/py.svg' },
  { code: 'PE', name: 'Peru', dialCode: '+51', flag: 'https://flagcdn.com/pe.svg' },
  { code: 'PH', name: 'Philippines', dialCode: '+63', flag: 'https://flagcdn.com/ph.svg' },
  { code: 'PN', name: 'Pitcairn', dialCode: '+872', flag: 'https://flagcdn.com/pn.svg' },
  { code: 'PL', name: 'Poland', dialCode: '+48', flag: 'https://flagcdn.com/pl.svg' },
  { code: 'PT', name: 'Portugal', dialCode: '+351', flag: 'https://flagcdn.com/pt.svg' },
  { code: 'PR', name: 'Puerto Rico', dialCode: '+1 787', flag: 'https://flagcdn.com/pr.svg' },
  { code: 'QA', name: 'Qatar', dialCode: '+974', flag: 'https://flagcdn.com/qa.svg' },
  { code: 'RE', name: 'Reunion', dialCode: '+262', flag: 'https://flagcdn.com/re.svg' },
  { code: 'RO', name: 'Romania', dialCode: '+40', flag: 'https://flagcdn.com/ro.svg' },
  { code: 'RU', name: 'Russian Federation', dialCode: '+7', flag: 'https://flagcdn.com/ru.svg' },
  { code: 'RW', name: 'Rwanda', dialCode: '+250', flag: 'https://flagcdn.com/rw.svg' },
  { code: 'SH', name: 'Saint Helena', dialCode: '+290', flag: 'https://flagcdn.com/sh.svg' },
  { code: 'KN', name: 'Saint Kitts and Nevis', dialCode: '+1-869', flag: 'https://flagcdn.com/kn.svg' },
  { code: 'LC', name: 'Saint Lucia', dialCode: '+1-758', flag: 'https://flagcdn.com/lc.svg' },
  { code: 'PM', name: 'Saint Pierre and Miquelon', dialCode: '+508', flag: 'https://flagcdn.com/pm.svg' },
  { code: 'VC', name: 'Saint Vincent and the Grenadines', dialCode: '+1-784', flag: 'https://flagcdn.com/vc.svg' },
  { code: 'WS', name: 'Samoa', dialCode: '+685', flag: 'https://flagcdn.com/ws.svg' },
  { code: 'SM', name: 'San Marino', dialCode: '+378', flag: 'https://flagcdn.com/sm.svg' },
  { code: 'ST', name: 'Sao Tome and Principe', dialCode: '+239', flag: 'https://flagcdn.com/st.svg' },
  { code: 'SA', name: 'Saudi Arabia', dialCode: '+966', flag: 'https://flagcdn.com/sa.svg' },
  { code: 'SN', name: 'Senegal', dialCode: '+221', flag: 'https://flagcdn.com/sn.svg' },
  //{ code: 'CS', name: 'Serbia and Montenegro', dialCode: '+381', flag: 'https://flagcdn.com/cs.svg' },
  { code: 'SC', name: 'Seychelles', dialCode: '+248', flag: 'https://flagcdn.com/sc.svg' },
  { code: 'SL', name: 'Sierra Leone', dialCode: '+232', flag: 'https://flagcdn.com/sl.svg' },
  { code: 'SG', name: 'Singapore', dialCode: '+65', flag: 'https://flagcdn.com/sg.svg' },
  { code: 'SK', name: 'Slovakia', dialCode: '+421', flag: 'https://flagcdn.com/sk.svg' },
  { code: 'SI', name: 'Slovenia', dialCode: '+386', flag: 'https://flagcdn.com/si.svg' },
  { code: 'SB', name: 'Solomon Islands', dialCode: '+677', flag: 'https://flagcdn.com/sb.svg' },
  { code: 'SO', name: 'Somalia', dialCode: '+252', flag: 'https://flagcdn.com/so.svg' },
  { code: 'ZA', name: 'South Africa', dialCode: '+27', flag: 'https://flagcdn.com/za.svg' },
  { code: 'GS', name: 'South Georgia and the South Sandwich Islands', dialCode: '+500', flag: 'https://flagcdn.com/gs.svg' },
  { code: 'ES', name: 'Spain', dialCode: '+34', flag: 'https://flagcdn.com/es.svg' },
  { code: 'LK', name: 'Sri Lanka', dialCode: '+94', flag: 'https://flagcdn.com/lk.svg' },
  { code: 'SD', name: 'Sudan', dialCode: '+249', flag: 'https://flagcdn.com/sd.svg' },
  { code: 'SR', name: 'Suriname', dialCode: '+597', flag: 'https://flagcdn.com/sr.svg' },
  { code: 'SJ', name: 'Svalbard and Jan Mayen', dialCode: '+47', flag: 'https://flagcdn.com/sj.svg' },
  { code: 'SZ', name: 'Swaziland', dialCode: '+268', flag: 'https://flagcdn.com/sz.svg' },
  { code: 'SE', name: 'Sweden', dialCode: '+46', flag: 'https://flagcdn.com/se.svg' },
  { code: 'CH', name: 'Switzerland', dialCode: '+41', flag: 'https://flagcdn.com/ch.svg' },
  { code: 'SY', name: 'Syrian Arab Republic', dialCode: '+963', flag: 'https://flagcdn.com/sy.svg' },
  { code: 'TW', name: 'Taiwan, Province of China', dialCode: '+886', flag: 'https://flagcdn.com/tw.svg' },
  { code: 'TJ', name: 'Tajikistan', dialCode: '+992', flag: 'https://flagcdn.com/tj.svg' },
  { code: 'TZ', name: 'Tanzania, United Republic of', dialCode: '+255', flag: 'https://flagcdn.com/tz.svg' },
  { code: 'TH', name: 'Thailand', dialCode: '+66', flag: 'https://flagcdn.com/th.svg' },
  { code: 'TL', name: 'Timor-Leste', dialCode: '+670', flag: 'https://flagcdn.com/tl.svg' },
  { code: 'TG', name: 'Togo', dialCode: '+228', flag: 'https://flagcdn.com/tg.svg' },
  { code: 'TK', name: 'Tokelau', dialCode: '+690', flag: 'https://flagcdn.com/tk.svg' },
  { code: 'TO', name: 'Tonga', dialCode: '+676', flag: 'https://flagcdn.com/to.svg' },
  { code: 'TT', name: 'Trinidad and Tobago', dialCode: '+1-868', flag: 'https://flagcdn.com/tt.svg' },
  { code: 'TN', name: 'Tunisia', dialCode: '+216', flag: 'https://flagcdn.com/tn.svg' },
  { code: 'TR', name: 'Turkey', dialCode: '+90', flag: 'https://flagcdn.com/tr.svg' },
  { code: 'TM', name: 'Turkmenistan', dialCode: '+993', flag: 'https://flagcdn.com/tm.svg' },
  { code: 'TC', name: 'Turks and Caicos Islands', dialCode: '+1-649', flag: 'https://flagcdn.com/tc.svg' },
  { code: 'TV', name: 'Tuvalu', dialCode: '+688', flag: 'https://flagcdn.com/tv.svg' },
  { code: 'UG', name: 'Uganda', dialCode: '+256', flag: 'https://flagcdn.com/ug.svg' },
  { code: 'UA', name: 'Ukraine', dialCode: '+380', flag: 'https://flagcdn.com/ua.svg' },
  { code: 'AE', name: 'United Arab Emirates', dialCode: '+971', flag: 'https://flagcdn.com/ae.svg' },
  { code: 'GB', name: 'United Kingdom', dialCode: '+44', flag: 'https://flagcdn.com/gb.svg' },
  { code: 'US', name: 'United States', dialCode: '+1', flag: 'https://flagcdn.com/us.svg' },
  { code: 'UM', name: 'United States Minor Outlying Islands', dialCode: '+1', flag: 'https://flagcdn.com/um.svg' },
  { code: 'UY', name: 'Uruguay', dialCode: '+598', flag: 'https://flagcdn.com/uy.svg' },
  { code: 'UZ', name: 'Uzbekistan', dialCode: '+998', flag: 'https://flagcdn.com/uz.svg' },
  { code: 'VU', name: 'Vanuatu', dialCode: '+678', flag: 'https://flagcdn.com/vu.svg' },
  { code: 'VE', name: 'Venezuela', dialCode: '+58', flag: 'https://flagcdn.com/ve.svg' },
  { code: 'VN', name: 'Viet Nam', dialCode: '+84', flag: 'https://flagcdn.com/vn.svg' },
  { code: 'VG', name: 'Virgin Islands, British', dialCode: '+1-284', flag: 'https://flagcdn.com/vg.svg' },
  { code: 'VI', name: 'Virgin Islands, U.S.', dialCode: '+1-340', flag: 'https://flagcdn.com/vi.svg' },
  { code: 'WF', name: 'Wallis and Futuna', dialCode: '+681', flag: 'https://flagcdn.com/wf.svg' },
  { code: 'EH', name: 'Western Sahara', dialCode: '+212', flag: 'https://flagcdn.com/eh.svg' },
  { code: 'YE', name: 'Yemen', dialCode: '+967', flag: 'https://flagcdn.com/ye.svg' },
  { code: 'ZM', name: 'Zambia', dialCode: '+260', flag: 'https://flagcdn.com/zm.svg' },
  { code: 'ZW', name: 'Zimbabwe', dialCode: '+263', flag: 'https://flagcdn.com/zw.svg' },  
]
   