import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { message } from "antd";
import cn from "classnames";
import styles from "./UpdateCameraPlaceHolder.module.sass";
import Image from "../../../components/Image";
import Modal from "../../../components/Modal";
import TextInput from "../../../components/TextInput";
import Dropdown from "../../../components/Dropdown";
import CameraImageLight from "../../../assets/images/camera_Light.svg";
import CameraImageDark from "../../../assets/images/camera_Dark.svg";
import { create_camera, get_all_cameras, update_camera } from "../../../API/Camera";

const UpdateCameraPlaceHolder = ({ existingCamera, onCameraUpdate, setCameraUpdateTrigger}) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [cameras, setCameras] = useState([]);
  const [error, setError] = useState(null);
  const [autoFilledName, setAutoFilledName] = useState("");
  

  const initialValues = {
    id: existingCamera?.id || "",
    name: existingCamera?.name || "",
    camera_id: existingCamera?.deviceId || "",
    brand: existingCamera?.brand || "",
    selectedCamera: existingCamera?.label || ""
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("Camera name is required"),
    camera_id: Yup.string().required("Select a camera")
  });

  const getStoredCameras = () => {
    const cameras = localStorage.getItem("Camera");
    return cameras ? JSON.parse(cameras) : [];
  };

  const fetchAllCameras = async () => {
    try {
      const res = await get_all_cameras();
      const cameras = res.data;
      const storedCameras = getStoredCameras();

      const apiCameraIds = new Set(cameras.map((camera) => camera.id));
      const updatedCameras = storedCameras.filter((camera) =>
        apiCameraIds.has(camera.id)
      );
      localStorage.setItem("Camera", JSON.stringify(updatedCameras));
    } catch (error) {
      console.error("Error fetching cameras:", error);
      setError("Failed to fetch existing cameras.");
    }
  };
  useEffect(() => {
    async function getCameras() {
      try {
        await fetchAllCameras();

        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === "videoinput");
        
        const storedCameras = getStoredCameras();

        const filteredDevices = videoDevices.filter(
          device => !storedCameras.some(
            storedCamera => 
              storedCamera.deviceId === device.deviceId && 
              storedCamera.id !== existingCamera?.id
          )
        );

        const mappedDevices = filteredDevices.map((device) => ({
          deviceId: device.deviceId,
          label: device.label || `Camera ${device.deviceId.slice(0, 8)}`,
          value: device.deviceId
        }));

        setCameras(mappedDevices);
        
        if (existingCamera?.deviceId) {
          const matchingDevice = mappedDevices.find(
            device => device.deviceId === existingCamera.deviceId
          );
          if (matchingDevice) {
            setAutoFilledName(matchingDevice.label);
          }
        }
      } catch (error) {
        console.error("Error accessing camera: ", error);
        setError("Camera access denied. Please enable camera permissions.");
      }
    }

    if (visibleModal) {
      getCameras();
    }
  }, [visibleModal, existingCamera]);
  useEffect(() => {
    async function getCameras() {
      try {
        await fetchAllCameras();

        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === "videoinput");
        
        const storedCameras = getStoredCameras();

        const filteredDevices = videoDevices.filter(
          device => !storedCameras.some(
            storedCamera => storedCamera.deviceId === device.deviceId
          )
        );

        const mappedDevices = filteredDevices.map((device, index) => ({
          deviceId: device.deviceId,
          label: device.label || `Camera ${index + 1}`,
          value: device.deviceId 
        }));

        setCameras(mappedDevices);
      } catch (error) {
        console.error("Error accessing camera: ", error);
        setError("Camera access denied. Please enable camera permissions.");
      }
    }

    if (visibleModal) {
      getCameras();
    }
  }, [visibleModal]);

const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const formData = {
        id: existingCamera?.id,
        name: values.name || autoFilledName,
        camera_id: values.camera_id,
      };

      const res = await update_camera(formData);
      
      if (res.status === 200) {
        message.success("Camera updated successfully");
        setVisibleModal(false);
        
        const updatedCamera = {
          ...existingCamera,
          name: formData.name,
          deviceId: formData.camera_id,
          label: formData.name
        };
        
        onCameraUpdate?.(updatedCamera);
        setCameraUpdateTrigger(prev => prev + 1);
      }
    } catch (error) {
      if (error.response?.status === 400) {
        const errors = error.response.data.errors;
        message.error(Object.values(errors[0])[0]);
      } else {
        message.error("Failed to update camera. Please try again.");
      }
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <>
      <div className="relative">
        <Image
          className={styles.pic}
          src={CameraImageLight}
          srcDark={CameraImageDark}
          alt="Core"
        />
        <div className="absolute top-[65%] left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <button
            type="button"
            className={cn("button-stroke-camera", styles.button)}
            onClick={() => setVisibleModal(true)}
          >
            Edit Camera
          </button>
        </div>
      </div>
      
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <div className={cn(styles.head, "mb-[30px]")}>
          <div className={cn("title-purple", styles.title)}>Edit Camera</div>
        </div>
        
        {error && <div className="text-red-500 mb-4">{error}</div>}
        
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form className="space-y-4">
              <Field name="name">
                {({ field, meta }) => (
                  <TextInput
                  {...field}
                  label="Camera Name"
                  tooltip="Maximum 50 characters."
                  error={meta.touched && meta.error ? meta.error : ""}
                  required
                  value={values.name || autoFilledName}
                  onChange={(e) => {
                    setFieldValue('name', e.target.value);
                  }}
                />
                )}
              </Field>

              <Field name="camera_id">
                {({ field, meta }) => (
                  <Dropdown
                    {...field}
                    label="Select Camera"
                    tooltip="Select the camera to be used."
                    options={cameras.map(camera => camera.label)}
                    value={values.selectedCamera} 
                    setValue={(selectedLabel) => {
                      const selectedCamera = cameras.find(
                        camera => camera.label === selectedLabel
                      );
                      if (selectedCamera) {
                        setFieldValue('camera_id', selectedCamera.deviceId);
                        setFieldValue('selectedCamera', selectedCamera.label); 
                        setFieldValue('brand', selectedCamera.label);
                      }
                    }}
                    error={meta.touched && meta.error ? meta.error : ""}
                  />
                )}
              </Field>

              <div className={cn(styles.btns, "mt-12 flex justify-between gap-4")}>
                <button
                  type="button"
                  className={cn("button-stroke", styles.button)}
                  onClick={() => setVisibleModal(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={cn("button", styles.button)}
                  disabled={isSubmitting}
                >
                  Update Camera
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default UpdateCameraPlaceHolder;