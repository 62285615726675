import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { message } from "antd";
import cn from "classnames";
import styles from "./CameraPlaceholder.module.sass";
import Image from "../../../components/Image";
import Modal from "../../../components/Modal";
import TextInput from "../../../components/TextInput";
import Dropdown from "../../../components/Dropdown";
import CameraImageLight from "../../../assets/images/camera_Light.svg";
import CameraImageDark from "../../../assets/images/camera_Dark.svg";
import { create_camera, update_camera, get_all_cameras } from "../../../API/Camera";

const CameraPlaceholder = ({ onCameraUpdate, setCameraUpdateTrigger }) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [cameras, setCameras] = useState([]);
  const [error, setError] = useState(null);
  const [existingCamera, setExistingCamera] = useState(null);
  const [step, setStep] = useState('create');
  const [videoStream, setVideoStream] = useState(null);
  const [selectedCameraId, setSelectedCameraId] = useState("");

  const initialValues = {
    create: {
      name: "",
      camera_id: "",
    },
    update: {
      name: existingCamera?.name || "",
      camera_id: "",
      brand: ""
    }
  };

  const validationSchemas = {
    create: Yup.object({
      name: Yup.string().required("Camera name is required"),
    }),
    update: Yup.object({
      name: Yup.string().required("Camera name is required"),
      camera_id: Yup.string().required("Select a camera")
    })
  };

  const resetState = () => {
    setError(null);
    setExistingCamera(null);
    setSelectedCameraId("");
    setStep('create');
    if (videoStream) {
      videoStream.getTracks().forEach(track => track.stop());
      setVideoStream(null);
    }
  };

  const getStoredCameras = () => {
    const cameras = localStorage.getItem("Camera");
    return cameras ? JSON.parse(cameras) : [];
  };

  const fetchAllCameras = async () => {
    try {
      const res = await get_all_cameras();
      const cameras = res.data;
      const storedCameras = getStoredCameras();
      const apiCameraIds = new Set(cameras.map((camera) => camera.id));
      const updatedCameras = storedCameras.filter((camera) =>
        apiCameraIds.has(camera.id)
      );
      localStorage.setItem("Camera", JSON.stringify(updatedCameras));
    } catch (error) {
      console.error("Error fetching cameras:", error);
      setError("Failed to fetch existing cameras.");
    }
  };

  useEffect(() => {
    let mounted = true;
    
    async function getCameras() {
      try {
        if (step === 'update') {
          await fetchAllCameras();
          if (!mounted) return;
          
          const devices = await navigator.mediaDevices.enumerateDevices();
          const videoDevices = devices.filter(device => device.kind === "videoinput");
          const storedCameras = getStoredCameras();
          
          const filteredDevices = videoDevices.filter(
            device => !storedCameras.some(
              storedCamera => storedCamera.deviceId === device.deviceId
            )
          );
          
          if (mounted) {
            setCameras(filteredDevices);
          }
        }
      } catch (error) {
        console.error("Error accessing camera:", error);
        if (mounted) {
          setError("Camera access denied. Please enable camera permissions.");
        }
      }
    }

    if (visibleModal) {
      getCameras();
    }

    return () => {
      mounted = false;
    };
  }, [visibleModal, step]);

  useEffect(() => {
    if (!visibleModal) {
      resetState();
    }
  }, [visibleModal]);

  const addOrUpdateCameraInStorage = (camera) => {
    const cameras = getStoredCameras();
    const existingIndex = cameras.findIndex(c => c.id === camera.id);
    
    if (existingIndex > -1) {
      cameras[existingIndex] = camera;
    } else {
      cameras.push(camera);
    }
    localStorage.setItem("Camera", JSON.stringify(cameras));
  };

  const handleCreateSubmit = async (values, { setSubmitting }) => {
    try {
      const res = await create_camera({ name: values.name, camera_id: values.camera_id });
      
      if (res.status === 200) {
        message.success("Camera created successfully");
        
        const allCamerasRes = await get_all_cameras();
        const newCamera = allCamerasRes.data.find(camera => camera.name === values.name);
        
        const cameraToStore = {
          id: newCamera.id,
          name: newCamera.name,
          deviceId: values.camera_id,
          label: newCamera.name
        };
        addOrUpdateCameraInStorage(cameraToStore);        
        setExistingCamera(cameraToStore);
        setStep('update');
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleUpdateSubmit = async (values, { setSubmitting }) => {
    try {
      const updatePayload = {
        id: existingCamera?.id,
        name: values.name,
        deviceId: values.camera_id,
        brand: values.brand || "", 
      };
  
      const res = await update_camera(updatePayload);
      
      if (res.status === 200) {
        message.success("Camera updated successfully");
        
        const updatedCamera = {
          ...existingCamera,
          ...updatePayload,
          label: updatePayload.name
        };
        addOrUpdateCameraInStorage(updatedCamera);        
        onCameraUpdate?.(updatedCamera);
        await fetchAllCameras();
        setCameraUpdateTrigger(prev => prev + 1);
        
        setVisibleModal(false);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleApiError = (error) => {
    if (error.response?.status === 400) {
      const errors = error.response.data.errors;
      message.error(Object.values(errors[0])[0]);
    } else if (error.response?.status === 500 || error.response?.status === 403) {
      message.error(error.response.data.err);
    } else {
      message.error("Operation failed. Please try again.");
    }
  };

  const handleCameraSelection = async (cameraId) => {
    if (videoStream) {
      videoStream.getTracks().forEach(track => track.stop()); 
    }
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: { exact: cameraId } }
      });
      setVideoStream(stream);
      setSelectedCameraId(cameraId);
    } catch (err) {
      console.error("Error accessing camera:", err);
      setError("Failed to access the selected camera.");
    }
  };

  const handleClose = () => {
    setVisibleModal(false);
  };

  return (
    <>
      <div className="relative">
        <Image
          className={styles.pic}
          src={CameraImageLight}
          srcDark={CameraImageDark}
          alt="Core"
        />
        <div className="absolute top-[65%] left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <button
            type="button"
            className={cn("button-stroke-camera", styles.button)}
            onClick={() => setVisibleModal(true)}
          >
            Add Camera
          </button>
        </div>
      </div>
      
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={handleClose}
      >
        <div className={cn(styles.head, "mb-[30px]")}>
          <div className={cn("title-red", styles.title)}>
            Add Camera
          </div>
        </div>
        
        {error && <div className="text-red-500 mb-4">{error}</div>}
        
        <Formik
          initialValues={initialValues[step]}
          validationSchema={validationSchemas[step]}
          onSubmit={step === 'create' ? handleCreateSubmit : handleUpdateSubmit}
          enableReinitialize
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form className="space-y-4">
              <Field name="name">
                {({ field, meta }) => (
                  <TextInput
                    {...field}
                    label="Camera Name"
                    tooltip="Maximum 50 characters."
                    error={meta.touched && meta.error ? meta.error : ""}
                    required
                  />
                )}
              </Field>
              
              {step === 'update' && (
                <Field name="camera_id">
                  {({ field, meta }) => (
                    <Dropdown
                      {...field}
                      label="Select Camera"
                      tooltip="Select the camera to be used."
                      options={cameras.map(camera => 
                        camera.label || `Camera ${camera.deviceId}`
                      )}
                      value={cameras.find(camera => 
                        camera.deviceId === field.value
                      )?.label || ""}
                      setValue={(value) => {
                        const selectedCamera = cameras.find(
                          camera => camera.label === value
                        );
                        if (selectedCamera) {
                          handleCameraSelection(selectedCamera.deviceId);
                          setFieldValue(field.name, selectedCamera.deviceId);
                          setFieldValue("brand", selectedCamera.label || "");
                        }
                      }}
                      error={meta.touched && meta.error ? meta.error : ""}
                    />
                  )}
                </Field>
              )}
              
              {selectedCameraId && videoStream && (
                <div className={styles.videoContainer}>
                  <video
                    autoPlay
                    playsInline
                    ref={(video) => {
                      if (video) {
                        video.srcObject = videoStream;
                      }
                    }}
                  />
                </div>
              )}
              
              <div className={cn(styles.btns, "mt-12 flex justify-between gap-4")}>
                <button
                  type="button"
                  className={cn("button-stroke", styles.button)}
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={cn("button", styles.button)}
                  disabled={isSubmitting}
                >
                  Save
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default CameraPlaceholder;