import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import { Divider, Space } from "antd";
import styles from "./GeneratePicklList.module.sass";

import Modal from "../../../../../components/Modal";
import Radio from "../../../../../components/Radio";
import CustomToast from "../../../../../components/CustomToast";
import {
  ASSIGN_ORDERS,
  GET_ALL_EMPLOYEES,
  SEND_SELECTED_EMPLOYEE_ID,
} from "../../../../../API/Employee";
import Icon from "../../../../../components/Icon";

function GeneratePickListPopUp({
  setVisible,
  visible,
  checkedItems,
  updateFilter,
  handleCheckboxChange,
  selectedPickListType,
  orderDetails,
}) {
  const navigate = useNavigate();
  const [staffOptions, setStaffOptions] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const PAGE_SIZE = 10;

  const observer = useRef();
  const lastStaffElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const checkedIds = Object.keys(checkedItems).filter((id) => checkedItems[id]);
  const showToast = CustomToast();

  const fetchEmployees = async (currentPage) => {
    try {
      setLoading(true);
      const response = await GET_ALL_EMPLOYEES(currentPage, PAGE_SIZE);

      const newRoles = response.map((item) => ({
        label: `${item.full_name}`,
        value: item.id,
      }));

      if (currentPage === 1) {
        setStaffOptions([{ label: "None", value: null }, ...newRoles]);
      } else {
        setStaffOptions((prev) => [...prev, ...newRoles]);
      }
      setHasMore(newRoles.length === PAGE_SIZE);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        console.error(error.response.data.err);
      } else {
        console.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployees(page);
  }, [page]);
  useEffect(() => {
    setSelectedEmployee(null);
  }, []);

  const handleOk = async () => {
    setVisible(false);
    try {
      const formData = {
        assign_to: selectedEmployee,
        order_id_list: checkedIds.map(Number),
      };

      if (selectedEmployee !== null) {
        const unassign_order = await SEND_SELECTED_EMPLOYEE_ID(
          formData.order_id_list
        );
        if (unassign_order.status === 200) {
          const assign_order = await ASSIGN_ORDERS(formData);
          showToast(`Orders successfully assigned.`, "success");
        }
      }
      updateFilter();
      const newCheckedItems = {};
      checkedIds.forEach((id) => (newCheckedItems[id] = false));
      handleCheckboxChange(null, newCheckedItems);

      if (selectedPickListType === "order") {
        navigate("/generatePickList", { state: { checkedItems } });
      } else if (selectedPickListType === "product") {
        navigate("/generatePickListByProduct", { state: { checkedItems } });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        showToast("Only admin can access", "error");
      } else {
        showToast("Failed to assign orders.", "error");
      }
    }
  };

  const handleNavigate = () => {
    navigate("/staff/add");
  };

  return (
    <Modal
      outerClassName={styles.outer}
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <div className={styles.details}>
        <div className={cn("title-purple", styles.title)}>Selected Orders</div>
        <p className={styles.subtitle}>{orderDetails}</p>
        <div className={styles.row}>
          <div className={styles.col}>
            <Divider />
            <p>Only generate picklist PDF</p>
            <span>
              Picklist will be generated for selected orders without changing
              order assignment.
            </span>
            <div className={cn("mt-3")}>
              <button
                className={cn("button", styles.button)}
                onClick={handleOk}
              >
                Generate Picklist
              </button>
            </div>
            <Divider />
            <div className={styles.col}>
              <div className={cn("mb-3")}>
                <p>Generate picklist PDF and assign orders</p>
                <span>
                  Picklist will be generated for selected orders and selected
                  orders can be assigned to a specific user.
                </span>
              </div>
              <div
                className={styles.radioContainer}
                style={{ maxHeight: "200px", overflowY: "auto" }}
              >
                <Space direction="vertical">
                  <Radio
                    key="none-option"
                    value={selectedEmployee === null}
                    onChange={() => setSelectedEmployee(null)}
                    content="None"
                    name="staff"
                    reverse
                  />
                  {staffOptions
                    .filter((role) => role.value !== null)
                    .map((role, index) => (
                      <div
                        ref={
                          index === staffOptions.length - 2
                            ? lastStaffElementRef
                            : null
                        }
                        key={`${role.value}-${index}`}
                      >
                        <Radio
                          value={selectedEmployee === role.value}
                          onChange={() => setSelectedEmployee(role.value)}
                          content={role.label}
                          name="staff"
                          reverse
                        />
                      </div>
                    ))}
                </Space>
              </div>
              <button
                className={cn("button mt-[20px]", styles.button)}
                onClick={handleNavigate}
              >
                <Icon name="add" size="24" />
                <span>Add Member</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default GeneratePickListPopUp;
