import React, { useState } from "react";
import cn from "classnames";
import styles from "./SignIn.module.sass";
import { use100vh } from "react-div-100vh";
import Entry from "./Entry";
import Code from "./Code";
import Stores from "./Stores";
import Image from "../../components/Image";
import { Link } from "react-router-dom";
import ppg_logo from "../../assets/images/logo_ppg.svg";
import ppg_logo_in_dark from "../../assets/images/ppg_logo_in_dark.svg";

const SignIn = () => {
  const [step, setStep] = useState(1);
  const [userData, setUserData] = useState();
  const [selectedTab, setSelectedTab] = useState("email");
  const [inputValue, setInputValue] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [verificationCode, setVerificationCode] = useState("");
  const heightWindow = use100vh();

  return (
    <div className={styles.row}>
      <div className={styles.col} style={{ minHeight: heightWindow }}>
        {step === 2 && (
          <Stores
            onConfirm={() => setStep(3)}
            userData={userData}
            setUserData={setUserData}
            selectedTab={selectedTab}
            confirmationResult={confirmationResult}
            setConfirmationResult={setConfirmationResult}
            verificationCode={verificationCode}
            setVerificationCode={setVerificationCode}
          />
        )}
        <div className={styles.wrapper}>
          {step !== 2 && (
            <>
              <div>
                <Link className={styles.logo} to="/">
                  <div className={styles.logo_img}>
                    <Image
                      src={ppg_logo}
                      srcDark={ppg_logo_in_dark}
                      alt="Core"
                    />
                  </div>
                </Link>
              </div>
              <div className={cn("h2", styles.title)}>Sign In</div>
            </>
          )}
          {step === 1 && (
            <Entry
              onConfirm={() => setStep(2)}
              setUserData={setUserData}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              inputValue={inputValue}
              setInputValue={setInputValue}
              setConfirmationResult={setConfirmationResult}
            />
          )}
          {step === 3 && (
            <Code
              userData={userData}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              inputValue={inputValue}
              setInputValue={setInputValue}
              setConfirmationResult={setConfirmationResult}
              verificationCode={verificationCode}
              setVerificationCode={setVerificationCode}
              confirmationResult={confirmationResult}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default SignIn;
