import React, { useState, useRef, useEffect } from "react";
import cn from "classnames";

import { findRoute } from "../../../utils/permissionUtils";

import styles from "./Code.module.sass";
import Loader from "../../../components/Loader";

import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { verifyOtp, verifyOtpWithMobile, reset } from "../../../features/auth/authSlice";
import CustomToast from "../../../components/CustomToast";

const Code = ({ userData, selectedTab, confirmationResult,verificationCode}) => {
  const showToast = CustomToast();
  const { isSuccess, message, isLoading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const inputs = useRef([]);
  const navigate = useNavigate();

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^\d$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);
      if (index < inputs.current.length - 1) {
        inputs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" || e.key === "Delete") {
      if (code[index]) {
        const newCode = [...code];
        newCode[index] = "";
        setCode(newCode);
      } else if (index > 0) {
        inputs.current[index - 1].focus();
      }
    } else if (e.key === "ArrowLeft" && index > 0) {
      inputs.current[index - 1].focus();
    } else if (e.key === "ArrowRight" && index < inputs.current.length - 1) {
      inputs.current[index + 1].focus();
    }
  };

  const verifyCode = async (otp) => {
    try {
      if (!confirmationResult) {
        throw new Error("Confirmation result not found. Please request a new OTP.");
      }  
      const result = await confirmationResult.confirm(otp);   
      const user = result.user;
      const idToken = await user.getIdToken();  
      const payload = {
        mobile_no: userData.mobile_no,
        store_id: userData.store_id,
        token: idToken,
      };
  
      dispatch(verifyOtpWithMobile(payload));
      showToast("Phone number verified successfully!", "success");
    } catch (error) {
      console.error("OTP Verification Error:", error.message);
      if (error.code === "auth/invalid-verification-code") {
        showToast("Invalid OTP. Please try again.", "error");
      } else if (error.code === "auth/code-expired") {
        showToast("The verification code has expired. Please request a new one.", "error");
      } else {
        showToast("Verification failed. Please try again.", "error");
      }
    }
  };
  const handleSubmit = async () => {
    const otp = code.join("");
    if (selectedTab === "phone") {
      await verifyCode(otp); 
    } else {
      const formData = {
        otp: otp,
        email: userData.email,
        store_id: userData.store_id,
      };
      dispatch(verifyOtp(formData)); 
    }
  };

  const isButtonDisabled = code.some((num) => num === "");

  useEffect(() => {
    if (isSuccess) {
      showToast("Login success", "success");
      dispatch(reset());
      const findPath = async () => {
        const firstAccessibleRoute = await findRoute();
        if (firstAccessibleRoute) {
          navigate(firstAccessibleRoute);
        } else {
          navigate("/unauthorized");
        }
      };
      findPath();
    } else if (message) {
      showToast(message.data.err, "error");
    }
  }, [isSuccess, message]);

  return (
    <div className={styles.code}>
      <div className={styles.body}>
        <div className={styles.info}>{userData.email}</div>
        <div className={styles.info}>
          {selectedTab === "phone" ?"We just sent you a verification code":"We just sent you a verification code. Check your inbox to get it."}
        </div>
        <div className={styles.fieldset}>
          {code.map((num, index) => (
            <div className={styles.field} key={index}>
              <input
                className={styles.input}
                name={`number${index + 1}`}
                type="tel"
                autoComplete="off"
                required
                value={num}
                onChange={(e) => handleChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                ref={(el) => (inputs.current[index] = el)}
              />
            </div>
          ))}
        </div>
        <div className={styles.errorNote}>
          The code you entered is incorrect.
        </div>
        <button
          className={cn("button", styles.button)}
          onClick={handleSubmit}
          disabled={isButtonDisabled || isLoading}
        >
          {isLoading ? (
            <Loader className={styles.loader} white />
          ) : (
            <span>Continue</span>
          )}
        </button>
        <div className={styles.note}>
          This site is protected by reCAPTCHA and the Google Privacy Policy.
        </div>
      </div>
    </div>
  );
};

export default Code;