import React, { useState } from "react";
import styles from "./Row.module.sass";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const Row = ({ item }) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const findProductImage = () => {
 
    if (item.product_variant && item.product_variant.image) {
      return item.product_variant.image ? item.product_variant.image.src : null;
    } else {
      if (item.product?.ProductImage) {
        return item.product.ProductImage?.[0]?.src;
      } else {
        return null
      }
    }
  };
  const image = findProductImage();
  return (
    <>
       <div
        className={styles.row}
        onClick={() => setVisibleModal(true)}
        data-tooltip-id={`row-tooltip-${item.id}`}
        data-tooltip-content={item.product_variant.variant_location}
      >
        <div className={styles.col}>
          <div className={styles.item}>
            {/* <div className={styles.preview}>
              {item.product.ProductImage.map((image, index) => (
                <img
                  srcSet={`${image.src} 2x`}
                  key={index}
                  src={image.src}
                  alt="Product"
                />
              ))}
            </div> */}
            <div className={styles.preview}>
              {image ? (
                <img srcSet={`${image} 2x`} src={image} alt="Product" />
              ) : (
                <div className="mt-[21%]">No Image</div>
              )}
            </div>
            <div className={styles.details}>
              <div className={styles.product}>{item.name}</div>
              <div className={styles.category}>
                {item.product && item.product.product_type
                  ? item.product.product_type
                  : ""}
              </div>
            </div>
            <Tooltip
              id={`row-tooltip-${item.id}`}
              className="mt-[110px] rounded-lg !rounded-lg"
              place="top"
              delayShow={300}
              delayHide={200}
            />
          </div>
        </div>
        {/* <div className={styles.col}>
          <div className={cn("status-yellow", styles.status)}>
            {item?.fulfillment_status
              ? item.fulfillment_status.replace(/_/g, " ")
              : "N/A"}
          </div>
        </div> */}
        <div className={styles.col}>
          {item.quantity < 10 ? `0${item.quantity}` : item.quantity}
        </div>
        <div className={styles.col}>{item.price}</div>
        <div className={styles.col}></div>
      </div>
      {/* <Details item={item} /> */}
      {/* <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
      
      </Modal> */}
    </>
  );
};

export default Row;