const getInputStyle = (darkMode, width) => ({
    width: width,
    height: "44px",
    backgroundColor: darkMode ? "#272B30" : "#FFFFFF",
    border: darkMode ? "2px solid transparent" : "1px solid #D1D5DB",
    borderRadius: "8px",
    padding: "0 10px",
    paddingLeft: "40px",
  });
  
  const getContainerStyle = (darkMode) => ({
    width: "100%",
    marginBottom: "15px",
    backgroundColor: darkMode ? "transparent" : "transparent",
  });
  
  const getButtonStyle = (darkMode) => ({
    backgroundColor: darkMode ? "#272B30" : "#FFFFFF",
    border: darkMode ? "2px solid transparent" : "1px solid #D1D5DB",
    borderRadius: "8px 0 0 8px",
  });
  
  const getDropdownStyle = (darkMode) => ({
    backgroundColor: darkMode ? "#1F2937" : "#FFFFFF",
    color: darkMode ? "#000000" : "#000000",
  });
  
  export { getInputStyle, getContainerStyle, getButtonStyle, getDropdownStyle };
  